import {Component, inject, OnInit} from '@angular/core';
import {OidcService} from '~/services';
import {Router} from '@angular/router';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrl: './logout.component.scss',
    standalone: true,
})
export class LogoutComponent implements OnInit {
  oidcService = inject(OidcService);
  router = inject(Router);

  ngOnInit(): void {
    this.oidcService.cleanLogin();
    setTimeout(() => {
      this.router.navigate(['/login']); // Replace '/target-route' with the route you want to redirect to
    }, 3000);
  }
}
